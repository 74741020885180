import consumer from "./consumer"

const subscribeToDeliveries = () => {
  const deliveries = document.querySelectorAll("tr[data-delivery-id]")

  deliveries.forEach((delivery) => {
    consumer.subscriptions.create(
      { channel: "DeliveriesChannel", id: delivery.dataset.deliveryId },
      {
        received(data) {
          let statusElement = delivery.querySelector(
            "td[data-attribute='status'] span"
          )

          statusElement.textContent = data.delivery_status
          statusElement.style.cssText = `color: #${data.delivery_status_color}; background-color: #${data.delivery_status_bgcolor};`
        },
      }
    )
  })
}

export default subscribeToDeliveries
