window.addEventListener("DOMContentLoaded", () => {
  const organizationAdminCheckbox = document.getElementById(
    "user_organization_admin"
  )

  if (organizationAdminCheckbox) {
    organizationAdminCheckbox.addEventListener("change", function () {
      toggleDepartmentsSelection(this.checked)
    })
  }
})

function toggleDepartmentsSelection(checked) {
  const departmentSelectionWrapper = document.getElementById(
    "department_selection_wrapper"
  )

  if (departmentSelectionWrapper) {
    if (checked) {
      departmentSelectionWrapper.classList.add("disabled-area")
    } else {
      departmentSelectionWrapper.classList.remove("disabled-area")
    }
  }
}
