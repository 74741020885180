if ("OTPCredential" in window) {
  window.addEventListener("DOMContentLoaded", () => {
    const input = document.querySelector('input[autocomplete="one-time-code"]')
    if (!input) return
    const ac = new AbortController()
    const form = input.closest("form")
    if (form) {
      form.addEventListener("submit", () => {
        ac.abort()
      })
    }
    navigator.credentials
      .get({
        otp: { transport: ["sms"] },
        signal: ac.signal,
      })
      .then((otp) => {
        input.value = otp.code
        if (form) form.submit()
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  })
}
