window.addEventListener("DOMContentLoaded", () => {
  const accountNumberInput = document.querySelector(
    "input#department_account_number"
  )
  const warningContainer = document.querySelector(".account-number-warning")

  if (accountNumberInput === null) return

  accountNumberInput.addEventListener("keyup", (e) => {
    if (accountNumberInput.dataset.value != e.target.value) {
      warningContainer.classList.remove("d-none")
    } else {
      warningContainer.classList.add("d-none")
    }
  })
})
